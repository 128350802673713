import Image from "next/image";
import { Box, SxProps } from "@mui/material";

type Props = {
  size?: number;
  sx?: SxProps;
};
function LoaderComponent({ size = 60, sx }: Props) {
  return (
    <Box
      sx={{
        borderRadius: "50%",
        width: size,
        height: size,
        animation: "spin 1s linear infinite",
        margin: "auto",
        "@keyframes spin": {
          "0%": {
            transform: "rotate(0deg)",
          },
          "100%": {
            transform: "rotate(360deg)",
          },
        },

        ...sx,
      }}
    >
      <Image src="/icons/loader.png" width={size} height={size} alt="loader" />
    </Box>
  );
}

export default LoaderComponent;
