import { Box, Skeleton, Stack } from "@mui/material";

const LoaderCompanyCardComponent = () => {
  return (
    <Box
      display="grid"
      gridTemplateColumns={{
        xs: "80px 1fr",
        md: "140px 1fr",
        lg: "217px 1fr",
      }}
      columnGap={{
        xs: "16px",
        md: "24px",
        lg: "32px",
      }}
    >
      <Skeleton
        variant="rounded"
        sx={{
          gridRow: {
            md: "span 2",
          },
          width: {
            xs: "80px",
            md: "140px",
            lg: "217px",
          },
          height: {
            xs: "71px",
            md: "140px",
            lg: "200px",
          },
        }}
      />

      <Stack
        py={{
          xs: 0,
          md: 2,
          lg: "24px",
        }}
      >
        <Skeleton
          variant="rounded"
          sx={{
            width: "100%",
            height: "30px",
            maxWidth: "260px",
            mb: "24px",
          }}
        />
        <Skeleton
          variant="rounded"
          sx={{
            width: "100%",
            mb: "10px",
            height: "20px",
          }}
        />

        <Skeleton
          variant="rounded"
          sx={{
            width: "85%",
            height: "20px",
          }}
        />
      </Stack>

      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        sx={{
          mb: {
            xs: 0,
            md: 2,
            lg: "24px",
          },
          gridColumn: {
            xs: "1 / 3",
            md: "auto",
          },
        }}
      >
        <Skeleton
          variant="rounded"
          sx={{
            mt: "8px",
            mr: "8px",
            width: "102px",
            height: "30px",
          }}
        />
        <Skeleton
          variant="rounded"
          sx={{
            mr: "8px",
            mt: "8px",
            width: "157px",
            height: "30px",
          }}
        />
        <Skeleton
          variant="rounded"
          sx={{
            mt: "8px",
            width: "97px",
            height: "30px",
          }}
        />
      </Stack>
    </Box>
  );
};

export default LoaderCompanyCardComponent;
