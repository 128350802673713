"use client";

import Link from "next/link";
import React from "react";
import { Breadcrumbs, SxProps, Typography, useMediaQuery } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";

import colors from "@/lib/palette";
import theme from "@/lib/theme";
import { Icon } from "@/shared/components";

export type Breadcramps = {
  name: string;
  href: string;
};

type Props = {
  children?: React.ReactNode;
  breadcramps: Breadcramps[];
};

const BreadcrumbItem = ({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: SxProps;
}) => {
  return (
    <Typography
      component="span"
      variant="subtitle1"
      sx={{
        color: colors.black500,
        display: "inline-block",
        "&:first-letter": {
          textTransform: "capitalize",
        },

        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

const BreadcrumbsComponent = ({ breadcramps }: Props) => {
  const isSmallScreen: boolean = useMediaQuery<boolean>(
    theme.breakpoints.down("sm")
  );

  const breadcrampsElements: React.ReactNode[] = breadcramps.map(
    (breadcramp: Breadcramps, index: number) => {
      if (index === breadcramps.length - 1) {
        return (
          <BreadcrumbItem key={breadcramp.href}>
            {breadcramp.name}
          </BreadcrumbItem>
        );
      }

      if (breadcramp.href === "/") {
        return (
          <Link
            key={breadcramp.href}
            href={breadcramp.href}
            style={{ textDecoration: "none", display: "flex" }}
          >
            <Icon icon="home" width={20} height={20} color={colors.blue} />
          </Link>
        );
      }

      return (
        <Link
          key={index}
          href={breadcramp.href}
          style={{ textDecoration: "none" }}
        >
          <BreadcrumbItem
            sx={{
              transition: "color 0.1s ease",
              ":hover": {
                color: colors.blue,
              },
            }}
          >
            {breadcramp.name}
          </BreadcrumbItem>
        </Link>
      );
    }
  );
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {!isSmallScreen ? (
        breadcrampsElements
      ) : (
        <Link
          href={breadcramps[breadcramps.length - 2].href}
          style={{ textDecoration: "none" }}
        >
          <BreadcrumbItem
            sx={{
              color: colors.blue,
            }}
          >
            <ArrowBackIos
              sx={{
                fontSize: "12px",
                verticalAlign: "middle",
              }}
            />
            {breadcramps[breadcramps.length - 2].name}
          </BreadcrumbItem>
        </Link>
      )}
    </Breadcrumbs>
  );
};

export default BreadcrumbsComponent;
