import React from "react";
import BadgePrimary from "./badge-primary.component";
import BadgeSecondary from "./badge-secondary.component";
import BadgeTertiary from "./badge-tertiary.component";
import { SxProps } from "@mui/material";

type Props = {
  variant?: "primary" | "secondary" | "tertiary" | "quaternary";
  children?: React.ReactNode;
  selected?: boolean;
  cancable?: boolean;
  href?: string;
  sx?: SxProps;
  onClick?: () => void;
};

const Badge = ({
  variant = "primary",
  children,
  cancable,
  selected,
  href,
  sx,
  onClick,
}: Props) => {
  if (variant === "secondary") {
    return <BadgeSecondary onClick={onClick}>{children}</BadgeSecondary>;
  }

  if (variant === "primary") {
    return (
      <BadgePrimary
        cancable={cancable}
        selected={selected}
        sx={sx}
        onClick={onClick}
      >
        {children}
      </BadgePrimary>
    );
  }

  if (variant === "tertiary") {
    return (
      <BadgeTertiary href={href} onClick={onClick}>
        {children}
      </BadgeTertiary>
    );
  }

  return <div>Badge</div>;
};

export default Badge;
