"use client";
import { useEffect, useState } from "react";

import {
  Box,
  Checkbox,
  MenuItem,
  MenuList,
  Paper,
  SxProps,
  Typography,
} from "@mui/material";
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";
import {
  SearchInput,
  ClickOutside,
  Observable,
  Loader,
} from "@/shared/components";
import colors from "@/lib/palette";
import { higlightedText } from "../utils";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

type Props<T, V> = {
  searchValue: string;
  items: T[];
  value: V[];
  loading?: boolean;
  listLoading?: boolean;
  searchPlaceholder?: string;
  sx?: SxProps;
  onSearch: (value: string) => void;
  onSelect: (value: V[]) => void;
  onIntersect?: () => void;
  renderSelectedItems?: (
    value: V[],
    handleSelect: (name: V) => void
  ) => JSX.Element;
};

type Item = {
  id: string | number;
  name: string;
};

const DropdownComponent = <T extends Item, V extends string>({
  value,
  searchValue,
  searchPlaceholder,
  loading,
  listLoading,
  items,
  sx,
  onIntersect,
  onSearch,
  onSelect,
  renderSelectedItems,
}: Props<T, V>) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [localValue, setLocalValue] = useState<V[]>(value);

  const handleClickOutsideBox = () => {
    setShowMenu(false);
  };

  const handleFocus = () => {
    setShowMenu(true);
  };

  const handleSelect = (name: V, selectClick: boolean = false) => {
    const newValue = value.includes(name as unknown as V)
      ? value.filter((v) => v !== name)
      : [...value, name];

    onSelect(newValue);

    if (selectClick) {
      setLocalValue(newValue);
    }
  };

  const isSelectedItem = (name: V) => localValue.includes(name as unknown as V);

  useEffect(() => {
    if (!showMenu) {
      setLocalValue(value);
    }
  }, [value, showMenu]);

  return (
    <ClickOutside onClickOutside={handleClickOutsideBox} sx={sx}>
      <Box width={"100%"}>
        <SearchInput
          value={searchValue}
          loading={loading}
          placeholder={searchPlaceholder}
          prepend={
            renderSelectedItems && renderSelectedItems(value, handleSelect)
          }
          onInput={onSearch}
          onFocus={handleFocus}
        />
      </Box>

      {!loading && showMenu ? (
        <Paper
          sx={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
            marginTop: "8px",
            zIndex: 4,
            padding: 0,
            background: colors.white,
            borderRadius: "4px",
            boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.05)",
            border: `1px solid ${colors.black500}`,
          }}
        >
          <MenuList
            sx={{
              maxHeight: "254px",
              overflowY: "auto",
            }}
          >
            {!items.length ? (
              <Typography
                variant="subtitle1"
                textAlign="center"
                fontSize={{
                  xs: "14px",
                }}
              >
                No matches found
              </Typography>
            ) : (
              <Box>
                {localValue
                  ?.filter((k) => {
                    return k.toLowerCase().includes(searchValue.toLowerCase());
                  })
                  .map((k) => (
                    <MenuItem
                      data-name={k}
                      key={`selected_keyword_${k}`}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0px",
                        whiteSpace: "break-spaces",
                      }}
                      onClick={() => handleSelect(k as V, true)}
                    >
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={value.includes(k as unknown as V)}
                      />

                      <Typography
                        color={colors.black}
                        fontSize={{ xs: "14px" }}
                      >
                        #{higlightedText(k, searchValue || "")}
                      </Typography>
                    </MenuItem>
                  ))}

                {items
                  .filter((k) => !isSelectedItem(k.name as unknown as V))
                  .map((k) => (
                    <MenuItem
                      data-name={k.name}
                      key={`keyword_${k.id}`}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0px",
                        whiteSpace: "break-spaces",
                      }}
                      onClick={() => handleSelect(k.name as V)}
                    >
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={value.includes(k.name as unknown as V)}
                      />

                      <Typography
                        color={colors.black}
                        fontSize={{ xs: "14px" }}
                      >
                        #{higlightedText(k.name, searchValue || "")}
                      </Typography>
                    </MenuItem>
                  ))}

                {listLoading ? (
                  <>
                    <MenuItem>
                      <Loader size={16} />
                    </MenuItem>
                  </>
                ) : (
                  <></>
                )}

                {onIntersect ? <Observable onIntersect={onIntersect} /> : <></>}
              </Box>
            )}
          </MenuList>
        </Paper>
      ) : (
        ""
      )}
    </ClickOutside>
  );
};

export default DropdownComponent;
