"use client";
import React, { useEffect, useRef } from "react";

type ObservableProps = {
  onIntersect: () => void;
  threshold?: number;
  rootMargin?: string;
};

const ObservableComponent: React.FC<ObservableProps> = ({
  onIntersect,
  threshold = 0.1,
  rootMargin = "0px",
}) => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!elementRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          onIntersect();
        }
      },
      {
        root: null,
        rootMargin,
        threshold,
      }
    );

    observer.observe(elementRef.current);

    return () => {
      observer.disconnect();
    };
  }, [onIntersect, threshold, rootMargin]);

  return <div ref={elementRef} style={{ height: "1px" }} />;
};

export default ObservableComponent;
