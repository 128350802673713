"use client";

import { Box, SxProps } from "@mui/material";
import React, { useEffect, useRef } from "react";

type Props = {
  children: React.ReactNode;
  onClickOutside?: () => void;
  onClick?: () => void;
  sx?: SxProps;
};

const ClickOutsideComponent = ({
  children,
  onClickOutside,
  onClick,
  sx,
}: Props) => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        divRef.current &&
        !divRef.current.contains(event.target as Node) &&
        onClickOutside
      ) {
        onClickOutside();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box
      ref={divRef}
      sx={{
        position: "relative",

        ...sx,
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

export default ClickOutsideComponent;
