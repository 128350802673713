"use client";
import Link from "next/link";
import { Box, Container, Stack, SxProps, Typography } from "@mui/material";
import { Button, Icon } from "@/shared/components";
import colors from "@/lib/palette";
import Image from "next/image";

type Props = {
  message?: string;
  sx?: SxProps;
};

function ErrorComponent({ message, sx }: Props) {
  return (
    <Container
      sx={{
        padding: {
          xs: "74px 0 50px",
          sm: "48px 0 100px",
          md: "48px 0 100px",
          lg: "48px 0 100px",
          xl: "80px 0 100px",
        },

        ...sx,
      }}
    >
      <Stack alignItems="center">
        <Box
          width="100%"
          maxWidth={{
            xs: "298px",
            md: "490px",
            lg: "715px",
          }}
          mb={4}
        >
          <Image
            src={"/404.png"}
            alt="404"
            width={1200}
            height={1200}
            className="next-image"
          />
        </Box>
        <Typography variant="h1" component="h1" textAlign="center">
          {message || "Oops, there's something wrong!"}
        </Typography>

        <Link
          href={"/"}
          style={{
            display: "flex",
            maxWidth: "300px",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Button
            variant="outlined-primary"
            sx={{
              maxWidth: {
                xs: "141px",
                sm: "169px",
                md: "182px",
              },
              padding: 0,
              alignItems: "center",
              "& .MuiBox-root": {
                marginRight: 0,
              },
              width: "100%",
              marginTop: {
                xs: "24px",
                sm: "48px",
                xl: "56px",
              },
            }}
            prepend={
              <Icon
                width={{ xs: 21 }}
                height={{ xs: 21 }}
                color={colors.blue}
                icon="home"
              />
            }
          >
            Back to home
          </Button>
        </Link>
      </Stack>
    </Container>
  );
}

export default ErrorComponent;
