import colors from "@/lib/palette";
import { Chip, SxProps, Typography } from "@mui/material";
import Link from "next/link";
import React from "react";

type Props = {
  children: React.ReactNode;
  href?: string;
  onClick?: () => void;
  sx?: SxProps;
};

const LabelComponent = ({
  children,
  sx,
  onClick,
}: {
  children: React.ReactNode;
  sx?: SxProps;
  onClick?: () => void;
}) => {
  return (
    <Chip
      label={
        <Typography
          component="span"
          fontSize={{
            xs: "11px",
            md: "14px",
          }}
          sx={{
            color: colors.black500,
            lineHeight: "12px",
            fontStyle: "normal",
            fontWeight: 400,
            letterSpacing: "-0.28px",
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            whiteSpace: "pre-wrap",
            textOverflow: "ellipsis",

            ...sx,
          }}
          onClick={onClick}
        >
          {children}
        </Typography>
      }
      sx={{
        height: "auto",
        background: colors.white,
        border: `1px solid ${colors.black400}`,
        padding: "4.5px 6px",
        borderRadius: 1,
        lineHeight: "12px",
        overflow: "hidden",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        whiteSpace: "pre-wrap",
        textOverflow: "ellipsis",

        gap: "4px",
        "& .MuiChip-label": {
          paddingLeft: 0,
          paddingRight: 0,
        },
        "& .MuiChip-icon": {
          marginLeft: 0,
          marginRight: 0,
        },
      }}
    />
  );
};

const BadgeTertiaryComponent = ({ children, sx, href, onClick }: Props) => {
  if (!href) {
    return (
      <LabelComponent sx={sx} onClick={onClick}>
        {children}
      </LabelComponent>
    );
  }
  return (
    <Link
      style={{
        display: "inline-block",
        maxWidth: "100%",
      }}
      href={href}
    >
      <LabelComponent sx={sx} onClick={onClick}>
        {children}
      </LabelComponent>
    </Link>
  );
};

export default BadgeTertiaryComponent;
