import ButtonDanger from "./button-danger";
import ButtonFilled from "./button-filled";
import ButtonOutlinedPrimary from "./button-outlined-primary";
import ButtonOutlinedSecondary from "./button-outlined-secondary";

import { PropsGeneral } from "./buttons.type";

const ButtonComponent = (props: PropsGeneral) => {
  const { variant = "filled" } = props;

  switch (variant) {
    case "filled":
      return <ButtonFilled {...props} />;
    case "outlined-primary":
      return <ButtonOutlinedPrimary {...props} />;
    case "outlined-secondary":
      return <ButtonOutlinedSecondary {...props} />;
    case "danger":
      return <ButtonDanger {...props} />;
    default:
      return <ButtonFilled {...props} />;
  }
};

export default ButtonComponent;
