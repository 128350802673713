"use client";
import { useEffect, useRef, useState, useCallback } from "react";
import { InputBase, InputBaseProps } from "@mui/material";

type Props = {
  value: string;
  timeout?: number;
  onInputDebounce: (value: string) => void;
};

const DebounceInputComponent = ({
  timeout = 300,
  value,
  onInputDebounce,
  ...props
}: Props & InputBaseProps) => {
  const [inputValue, setValue] = useState<string>(value);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const debouncedOnInput = useCallback(
    (input: string) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        onInputDebounce(input);
      }, timeout);
    },
    [onInputDebounce, timeout]
  );

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    debouncedOnInput(newValue);
  };

  useEffect(() => {
    setValue(value);
  }, [value]);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return <InputBase {...props} value={inputValue} onChange={handleInput} />;
};

export default DebounceInputComponent;
