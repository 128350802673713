import { SvgIcon } from "@mui/material";
import colors from "@/lib/palette";
import IconProps from "./icon.types";

const Icon = (props: IconProps) => {
  const { width, height, color, icon, className, sx, ...othersProps } = props;

  return (
    <SvgIcon
      component="svg"
      className={className}
      sx={{
        fill: color || colors.black500,
        width: width || { xs: 18, md: 26 },
        height: height || { xs: 18, md: 26 },
        transition: "fill 0.2s ease-in-out",
        ...sx,
      }}
      {...othersProps}
    >
      <use href={`/icons.sprite.svg#icon-${icon || "sales-tools"}`} />
    </SvgIcon>
  );
};

export default Icon;
