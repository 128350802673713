import { Box, Skeleton, Stack } from "@mui/material";

const LoaderResourceCardComponent = () => {
  return (
    <Box>
      <Stack flexDirection="row" mb={1} alignItems="center">
        <Skeleton
          variant="circular"
          sx={{
            width: {
              xs: 26,
              md: 32,
              lg: 40,
            },
            height: {
              xs: 26,
              md: 32,
              lg: 40,
            },
          }}
        />
        <Skeleton
          variant="rounded"
          sx={{
            flex: 1,
            ml: "12px",
            height: {
              xs: 16,
              md: 20,
              lg: 24,
            },
          }}
        />
      </Stack>

      <Skeleton
        variant="rounded"
        sx={{
          width: "100%",
          height: {
            xs: 124,
            md: 180,
            lg: 230,
          },
          mb: 2,
        }}
      />

      <Stack spacing="10px">
        <Skeleton
          variant="rounded"
          sx={{
            width: "80%",
            height: {
              xs: 16,
              md: 24,
              lg: 30,
            },
          }}
        />

        <Skeleton
          variant="rounded"
          sx={{
            width: "100%",
            height: {
              xs: 10,
              md: 15,
              lg: 20,
            },
          }}
        />

        <Skeleton
          variant="rounded"
          sx={{
            width: "100%",
            height: {
              xs: 10,
              md: 15,
              lg: 20,
            },
          }}
        />

        <Skeleton
          variant="rounded"
          sx={{
            width: "50%",
            height: {
              xs: 10,
              md: 15,
              lg: 20,
            },
          }}
        />
      </Stack>
    </Box>
  );
};

export default LoaderResourceCardComponent;
