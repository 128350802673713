import React from "react";
import { SxProps, Typography } from "@mui/material";
import colors from "@/lib/palette";

type Props = {
  children: React.ReactNode;
  onClick?: () => void;
  sx?: SxProps;
};

const BadgeSecondaryComponent = ({ children, sx, onClick }: Props) => {
  return (
    <Typography
      fontSize={{
        xs: "12px",
        md: "14px",
      }}
      color={colors.blue}
      padding={{
        xs: "6px 8px",
        md: "8px 10px",
      }}
      border={`1px solid ${colors.blue30}`}
      borderRadius={`40px`}
      onClick={onClick}
      sx={{
        transition: "border 0.2s",
        "&:hover": {
          border: `1px solid ${colors.blue}`,
        },

        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};

export default BadgeSecondaryComponent;
