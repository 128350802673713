"use client";

import {
  Box,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import {
  ChevronLeft,
  ChevronRight,
  ArrowBack,
  ArrowForward,
} from "@mui/icons-material";

import theme from "@/lib/theme";
import colors from "@/lib/palette";

type Props = {
  page: number;
  count: number;
  onChangePage: (page: string) => void;
};

const Previous = () => {
  const isSmallScreen: boolean = useMediaQuery<boolean>(
    theme.breakpoints.down("sm")
  );
  if (isSmallScreen) {
    return <ChevronLeft htmlColor={colors.blue} />;
  }
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        p: 1,
      }}
    >
      <ArrowBack htmlColor={colors.blue} />
      {!isSmallScreen ? (
        <Typography
          variant="button"
          sx={{
            color: colors.blue,
            textTransform: "capitalize",
          }}
        >
          Previous
        </Typography>
      ) : (
        ""
      )}
    </Box>
  );
};

const Next = () => {
  const isSmallScreen: boolean = useMediaQuery<boolean>(
    theme.breakpoints.down("sm")
  );
  if (isSmallScreen) {
    return <ChevronRight htmlColor={colors.blue} />;
  }
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        p: 1,
      }}
    >
      {!isSmallScreen ? (
        <Typography
          variant="button"
          sx={{
            color: colors.blue,
            textTransform: "capitalize",
          }}
        >
          Next
        </Typography>
      ) : (
        ""
      )}
      <ArrowForward htmlColor={colors.blue} />
    </Box>
  );
};

const PaginationsComponent = ({ count, page, onChangePage }: Props) => {
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    onChangePage(value.toString());
  };

  return (
    <Box
      sx={{
        paddingTop: 4,
        marginTop: 4,
        borderTop: `1px solid ${colors.black400}`,
        position: {
          xs: "relative",
          sm: "initial",
        },
      }}
    >
      <Stack spacing={2}>
        <Pagination
          count={count}
          page={page}
          shape="rounded"
          color="primary"
          onChange={handleChangePage}
          sx={{
            "& .MuiPagination-ul": {
              justifyContent: "center",
              position: "relative",
              "& li:first-child": {
                position: "absolute",
                left: 0,
              },
              "& li:last-child": {
                position: "absolute",
                right: 0,
              },
            },
          }}
          renderItem={(item) => {
            return (
              <PaginationItem
                slots={{ previous: Previous, next: Next }}
                sx={{
                  minWidth: {
                    xs: "22px",
                    sm: "32px",
                  },
                  fontSize: {
                    xs: "10px",
                    sm: "14px",
                  },

                  height: {
                    xs: "22px",
                    sm: "32px",
                  },
                  padding: "4px",
                  "&.Mui-selected": {
                    backgroundColor: colors.blue,
                    color: colors.white,

                    "&:hover": {
                      backgroundColor: colors.blue40,
                    },
                  },
                  "&.MuiPaginationItem-previousNext": {
                    border: `1px solid ${colors.blue30}`,
                    backgroundColor: colors.blue10,
                  },
                }}
                {...item}
              />
            );
          }}
        />
      </Stack>
    </Box>
  );
};

export default PaginationsComponent;
