import React from "react";
import { Box, SxProps, Typography } from "@mui/material";
import { DebounceInput, Loader, Icon } from "@/shared/components";
import colors from "@/lib/palette";

type Props = {
  value: string;
  placeholder?: string;
  loading?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  prepend?: React.ReactNode;
  sx?: SxProps;
  onInput: (value: string) => void;
  onFocus?: () => void;
};

const SearchInputComponent = React.memo(
  ({
    value,
    loading,
    disabled,
    placeholder,
    readonly,
    sx,
    prepend,
    onInput,
    onFocus,
  }: Props) => {
    return (
      <Typography
        component="div"
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        variant="subtitle1"
        bgcolor={colors.white}
        border={`1px solid ${colors.steel}`}
        borderRadius="4px"
        p="8px 14px"
        color={colors.black}
        sx={sx}
      >
        {prepend}
        <Box component="div" display="flex" alignItems="center" width="100%">
          <DebounceInput
            value={value}
            onInputDebounce={onInput}
            onFocus={onFocus}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readonly}
            sx={{
              fontSize: "inherit",
              color: "inherit",
              padding: 0,
              width: "100%",
              "& .MuiInputBase-input": {
                padding: 0,
                fontSize: "inherit",
                color: "inherit",
                height: "auto",
              },
            }}
          />
          <Box
            width={17}
            height={17}
            ml={1}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {loading ? (
              <Loader size={16} />
            ) : value ? (
              <Icon
                icon="close"
                width={16}
                height={16}
                color={colors.steel}
                sx={{
                  cursor: "pointer",
                  flex: "0 0 auto",
                }}
                onClick={() => {
                  if (onInput) {
                    onInput("");
                  }
                }}
              />
            ) : (
              <Icon icon="search" width={16} height={16} color={colors.steel} />
            )}
          </Box>
        </Box>
      </Typography>
    );
  }
);

SearchInputComponent.displayName = "SearchInputComponent";

export default SearchInputComponent;
